<template>
  <page-view>
    <div class="log_header_wrapper">
      <a-row :gutter="48" class="row">
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>用户ID</span>
            <a-input placeholder="用户ID" class="filter_input" v-model.trim="queryParam.uid" />
          </div>
        </a-col>

        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>调用类型</span>
            <a-input placeholder="调用类型" class="filter_input" v-model.trim="queryParam.type" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <span>查询时间</span>
            <a-range-picker
              class="filter_input"
              :value="[moment(queryParam.startCreateTime), moment(queryParam.endCreateTime)]"
              @change="onChangeDatePay"
            />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="time_select">
            <span
              v-for="item in shortcutDate"
              :key="item.id"
              @click="handleShortcutDate(item)"
              :class="currentShortcutDate === item.date ? 'active' : ''"
              >{{ item.title }}</span
            >
          </div>
        </a-col>
        <a-col :lg="3" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="
                () => {
                  $refs.table.refresh(false);
                }
              "
              >查询</a-button
            >
            <a-button @click="resetQuery">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </div>

    <div class="log_table_wrapper">
      <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource">
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <template slot="status" slot-scope="text, record, index">
          {{ text == 'SUCCESS' ? '成功' : '失败' }}
        </template>
        <template slot="uid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.uid)"
              @mouseover="handleMouseover(record, 'show')"
              @mouseout="handleMouseout(record, 'show')"
            >
              {{ text.substring(0, 2) + '……' + text.substring(text.split('').length - 2) }}
              <span class="uid_child" v-show="record.show">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
      </s-table>
    </div>
  </page-view>
</template>
<script>
import { STable } from '@/components';
import { PageView } from '@/layouts';
import { logAppListGet } from '@api';
import { formatDate } from '@/utils/util';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
export default {
  data() {
    return {
      currentShortcutDate: 0,
      queryParam: {
        startCreateTime: new Date(new Date().toLocaleDateString()).getTime(),
        endCreateTime: new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000
      },
      shortcutDate: [
        {
          id: 1,
          title: '今天',
          date: 0
        },
        {
          id: 2,
          title: '昨天',
          date: 1
        },
        {
          id: 3,
          title: '最近7天',
          date: 7
        },
        {
          id: 4,
          title: '最近30天',
          date: 30
        }
      ],
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户ID',
          dataIndex: 'uid',
          scopedSlots: { customRender: 'uid' }
        },

        {
          title: '调用类型',
          dataIndex: 'type'
        },
        {
          title: '调用状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },

        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: formatDate
        }
      ],

      dataSource: (parameter) => {
        return logAppListGet(Object.assign(parameter, this.queryParam)).then((result) => {
          result.data.list.forEach((item) => {
            item.show = false;
          });
          return result.data;
        });
      }
    };
  },

  components: {
    PageView,
    STable
  },
  methods: {
    moment,
    resetQuery() {
      this.currentShortcutDate = 0;
      this.queryParam = {
        startCreateTime: new Date(new Date().toLocaleDateString()).getTime(),
        endCreateTime: new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000
      };
      this.$refs.table.refresh(false);
    },
    onChangeDatePay(date, dateString) {
      const time = dateString.includes('');
      if (time) {
        delete this.queryParam.startCreateTime;
        delete this.queryParam.endCreateTime;
      } else {
        this.queryParam.startCreateTime = Date.parse(new Date(dateString[0])) - 1000 * 3600 * 8;
        this.queryParam.endCreateTime = Date.parse(new Date(dateString[1])) - 1000 * 3600 * 8;
      }
      this.currentShortcutDate = null;
      this.$refs.table.refresh(false);
    },
    handleShortcutDate(record) {
      this.currentShortcutDate = record.date;

      let startCreateTime;
      let endCreateTime;
      let today = new Date(new Date().toLocaleDateString()).getTime();
      // 今天
      if (record.date === 0) {
        startCreateTime = today;
        endCreateTime = today + 24 * 60 * 60 * 1000;
      }
      // 7天内  30天内
      if (record.date === 7 || record.date === 30) {
        endCreateTime = today + 24 * 60 * 60 * 1000;
        startCreateTime = today - 24 * 60 * 60 * 1000 * (record.date - 1);
      }
      // 昨天
      if (record.date === 1) {
        startCreateTime = today - 24 * 60 * 60 * 1000;
        endCreateTime = today;
      }
      this.queryParam.startCreateTime = startCreateTime;
      this.queryParam.endCreateTime = endCreateTime;
      this.$refs.table.refresh(false);
    },

    handleMouseover(record, key) {
      record[key] = true;
    },
    handleMouseout(record, key) {
      record[key] = false;
    }
  }
};
</script>

<style lang="less" scoped>
.log_header_wrapper {
  padding: 24px 56px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  .row {
    margin-left: 0 !important;
  }

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }

    .query_btn {
      margin-right: 12px;
    }
  }
  .time_select {
    span {
      display: inline-block;
      margin-right: 24px;
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
    .active {
      color: #1890ff;
    }
  }
}
.log_table_wrapper {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: PingFang SC;
  padding: 24px;
}

.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
</style>
